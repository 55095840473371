import Vuex from 'vuex';

export default new Vuex.Store({
    state () {
      return {
        loading: true,
        showMenu: false,
        warning: {
          show:false,
          label:""
        },
        success: {
          show:false,
          label:""
        },
        question: {
          show:false,
          label:""
        },
        alert: {
          show:false,
          message:"sukses",
          type: "success"
        },
      }
    },
    mutations: {
      setLoading (state,value) {
        state.loading = value;
      },
      setShowMenu (state,value) {
        state.showMenu = value;
      },
      setAlert (state,value) {
        state.alert = value;
        if (value.show) {
          setTimeout(() => {
            state.alert={show:false,
              message:"sukses",
              type: "success"}
          }, value.time??2000)
        }
      },
      setModalWarning (state,value) {
        state.warning = value;
      },
      setModalSuccess (state,value) {
        state.success = value;
      },
      setModalQuestion (state,value) {
        state.question = value;
      }
    }
});
