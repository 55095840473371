<template>
    <a-modal :open="show" title="">
        <template #footer>
            <div style="width: 100%;text-align: center;">
                <a-row>
                    <a-col :span="11">
                        <a class="f-l btn-warn-question" @click="onClick(this.$store.state.question?.clickNo)">
                            {{ this.$store.state.question?.clickNoLabel }}</a>
                    </a-col>
                    <a-col :span="2" style="text-align: center;">
                    </a-col>
                    <a-col :span="11">
                        <a class="f-l btn-info-question" @click="onClick(this.$store.state.question?.clickYes)">
                            {{ this.$store.state.question?.clickYesLabel }}</a>
                    </a-col>
                </a-row>

            </div>
        </template>
        <div style="height: 150px;height: 150px;text-align: center;color: #17456d;">
            <MyIcon style="font-size: 80px;" name="question-circle-outlined" />
            <p style="margin-top: 25px;" class="f-m" v-html="label"></p>
        </div>
    </a-modal>
</template>
 
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MyIcon from '@/components/MyIcon.vue';
export default ({
    name: 'MyAnswerModal',
    props: {
        show: Boolean,
        label: String,
        onClose: Function
    },
    components: {
        MyIcon
    },
    methods: {
        onClick: function (callback) {
            this.$store.commit('setModalQuestion', { show: false })
            callback()
        },
    }
})
</script>


<style>
.ant-select-dropdown {
    width: 75px !important;
}

.btn-info-question {
    display: inline-block;
    background: white;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    padding: 5px;
    color: #0b8bfb;
    border: 1px solid #0b8bfb;
}

.btn-info-question:hover {
    background: #0b8bfb;
    color: white;
}

.ant-modal-close {
    display: none !important;
}

.btn-warn-question {
    display: inline-block;
    background: white;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    padding: 5px;
    color: #f2c205ad;
    border: 1px solid #f2c205ad;
}

.btn-warn-question:hover {
    background: #f2c205ad;
    color: white;
}
</style>