import {vue3Debounce} from 'vue-debounce'
import { createApp } from 'vue';

import App from './App.vue';
import router  from './router.js';
import http from './helpers/http';
import formatString from './helpers/formatString';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import AvatarCropper from 'vue-avatar-cropper'
import store from './store.js'
// Create a new store instance.

const app = createApp(App);
app.config.globalProperties.$http = http.request;
app.config.globalProperties.$formatString = formatString;
app.directive('debounce', vue3Debounce({ lock: true }))
app.use(store)
app.use(AvatarCropper)
app.use(router);
app.use(Antd);
app.mount('#app');