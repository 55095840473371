<template>
    <a-modal :open="show" title="">
        <template #footer>
            <div style="width: 100%;text-align: center;">
                <a class="f-l btn-success" @click="onClose">ok</a>
            </div>
        </template>
        <div style="height: 150px;height: 150px;text-align: center;color: #e8833a;">
            <MyIcon style="font-size: 80px;" name="check-circle-outlined"/>
            <p style="margin-top: 25px;" class="f-m">{{ label }}</p>
        </div>
    </a-modal>
</template>
 
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MyIcon from '@/components/MyIcon.vue';
export default ({
    name: 'MySuccessModal',
    props: {
        show: Boolean,
        label: String,
        onClose: Function
    },
    components: {
        MyIcon
    },
})
</script>


<style>
.ant-select-dropdown {
    width: 75px !important;
}

.btn-success {
    display: inline-block;
    background: white;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    padding: 5px;
    color: #e8833a;
    border: 1px solid #e8833a;
}

.ant-modal-close {
    display: none !important;
}</style>