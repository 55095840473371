<template>
    <div>
        <div id="navMenu" class="navbar-bottom menu-list" :class="{ 'nav-open': this.$store.state.showMenu }"
            style="background:linear-gradient(rgb(94, 45, 10), rgb(232, 131, 58));">
            <div style="height: 120%;">
                <div class="close-menu">
                    <button class="btn-close" v-on:click="menuShow()">
                    </button>
                </div>
                <div style="height: 300px;overflow-y: hidden;color: #ffffff;font-size: 12px;">
                    <div  :onClick="()=>{this.$router.push(m.link);}" v-for="(m, i) of listMenu" :key="i" class="coll ln-b cursor-pointer" :class="{ 'activeMenu': activeMenu == m.key }">
                        <div class="coll-3 txt-c">
                            <a-space>
                                <MyIcon :name="m.icon"  style="font-size: 14px;"/>
                            </a-space>
                        </div>
                        <div class="coll-8">
                            <label>{{ m.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar-bottom fixed-bottom " style="overflow: visible;">
            <div class="menu-bar">
                    <div class="menu-bar-button" v-on:click="menuShow()">
                        <div v-if="this.$store.state.showMenu" style="font-size: 30px;
                            bottom: 7px;
                            position: absolute;">
                            <a-space>
                                <MyIcon name="close-outlined" />
                            </a-space>
                        </div>
                        <div v-else>
                            <a-space>
                                <MyIcon name="menu-outlined" />
                            </a-space>
                        </div>
                    </div>
                <p class="button-footer-text menu">Menu</p>
            </div>
            <div class="menu_bottom button-footer-1">
                <router-link to="/business">
                    <div align="center">
                        <a-space>
                            <div class="fa_house" :class="{ 'active': activeMenu == 'home' }">
                                <MyIcon name="home-filled" style="color: #e8833e;" />
                            </div>
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'home' }">Beranda</p>
                    </div>
                </router-link>
            </div>
            <div class="menu_bottom button-footer-2">
                <router-link to="/business/report">
                    <div align="center">
                        <a-space class="wallet_dompet" :class="{ 'active': activeMenu == 'report' }">
                                <MyIcon name="line-chart-outlined" style="color: #e8833e;" />
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'report' }">Laporan</p>
                    </div>
                </router-link>
            </div>
            <div class="menu_bottom button-footer-3">
                <router-link to="/business/profile">
                    <div align="center">
                        <a-space  class="lines" :class="{ 'active': activeMenu == 'profile' }">
                            <MyIcon name="user-outlined" style="color: #e8833e;"/>
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'profile' }">Profil</p>
                    </div>
                </router-link>
            </div>
            <div class="menu_bottom button-footer-4">
                <router-link to="/business/transaction">
                    <div align="center">
                        <a-space class="i_shop" :class="{ 'active': activeMenu == 'transaction' }">
                            <MyIcon style="color: #e8833e;" name="desktop-outlined" />
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'transaction' }">Transaksi</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
  
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MyIcon from '@/components/MyIcon.vue';

export default {
    name: 'MenuFooter',
    props: {
        activeMenu:Object
    },
    components: {
        MyIcon
    },
    data() {
        return {
            listMenu: [
                {
                    "label": "Data Pustaka",
                    "key": "library",
                    "link": "/business/library",
                    "icon": "read-outlined"
                },
                {
                    "label": "Operasional",
                    "key": "operational",
                    "link": "",
                    "icon": "table-outlined"
                },
                {
                    "label": "Keluar Dari Akun",
                    "key": "logout",
                    "link": "/business/logout",
                    "icon": "logout-outlined"
                },
            ]
        }
    },
    methods: {
        menuShow: function () {
            this.$store.commit("setShowMenu",!this.$store.state.showMenu);
        }
    },
    mounted() {
    }
}
</script>
  