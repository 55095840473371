<template >
    <svg :width="width ? width : '100%'" :height="height ? height : '100%'" version="1.1" id="long-pants" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 547 547" style="enable-background:new 0 0 547 547;" xml:space="preserve">
        <path class="long-pants" :style="styleCs" d="M462.956,539.82l-24.658-398.11V55.176c0-0.064,0-0.126,0-0.19V0h-332v62h0.39l-0.39,77.063v0.003
            c0,0.036,0.195,0.071,0.193,0.107L84.04,539.849c-0.105,1.858,0.613,3.678,1.889,5.03c1.276,1.357,3.107,2.121,4.966,2.121h151.661
            c3.516,0,6.444-2.69,6.745-6.196l23.742-280.133l26.9,280.21c0.334,3.468,3.248,6.12,6.736,6.12h149.654
            c1.873,0,3.584-0.769,4.869-2.131C462.483,543.513,463.071,541.683,462.956,539.82z M425.05,129.572
            C396.409,116.511,377.972,94,368.991,62h56.047L425.05,129.572z M288.299,164.951l-9,9.387V62h9V164.951z M425.299,13v35h-305V13
            H425.299z M175.853,62c-8.936,32-27.555,54.248-55.555,67.331V62H175.853z M236.345,533H98.055l21.9-389.025
            C156.241,129.289,179.72,102,189.855,62h76.443v117.705c-13-5.217-31.749-13.547-46.148-19.761c-3.43-1.486-7.524,0.105-9,3.535
            c-1.481,3.43,0.045,7.414,3.48,8.895c4.426,1.908,35.787,15.216,50.505,21.493L236.345,533z M312.832,533l-32.36-336.892
            c6.877-2.441,20.631-8.708,50.604-24.041c3.33-1.701,4.648-5.717,2.948-9.047c-1.705-3.334-5.799-4.609-9.105-2.914
            c-8.504,4.349-17.485,8.855-25.513,12.77l0.801-0.581c1.19-1.256,2.093-2.916,2.093-4.646V62h52.691
            c10.167,40,33.745,67.444,70.198,82.1L449.139,533H312.832z"/>
        <path class="long-pants" :style="styleCs" d="M220.227,490H113.452c-3.736,0-6.769,2.759-6.769,6.5c0,3.74,3.034,6.5,6.769,6.5h106.775
            c3.74,0,6.769-2.759,6.769-6.5C226.996,492.759,223.962,490,220.227,490z"/>
        <path class="long-pants" :style="styleCs" d="M325.749,490c-3.741,0-6.769,2.759-6.769,6.5c0,3.74,3.034,6.5,6.769,6.5h106.774
            c3.736,0,6.764-2.759,6.769-6.5c0-3.741-3.034-6.5-6.769-6.5H325.749z"/>
    </svg>
</template>
 
<script>
export default ({
    name: 'LongPants',
    props: {
        style: String,
        width: String
    },
    data (){
        let color = '#231F20';
        var styleCs = {
            fill : color,
            ...this.style
        };
        return{
            styleCs : styleCs
        }
    },  
})
</script>