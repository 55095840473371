<template>
  <div>
    <div class="template">
      <a-alert v-if="this.$store.state.alert.show" closable="true" :message="this.$store.state.alert.message"
        :type="this.$store.state.alert.type" show-icon
        :style="`position: fixed;top: ${this.$store.state.alert.top ?? '90'}px;z-index: 1;width: 100%;max-width: 480px;margin: 0 auto;transform: translateX(-50%);left: 50%;`" />
      <router-view v-slot="{ Component, route }">
        <a-spin :spinning="this.$store.state.loading" tip="Menunggu..." style="position: fixed;">
        <transition name="route" mode="out-in" appear>
          <component :is="Component" />
        </transition>
        </a-spin>
        <MenuFooter :activeMenu="route.meta.menu.name" v-if="route.meta.menu && route.meta.menu.show"></MenuFooter>
      </router-view>
      <ModalWarning :onClose="() => { this.$store.commit('setModalWarning', { show: false, label: '' }); }"
        :show="this.$store.state.warning?.show" :label="this.$store.state.warning?.label"></ModalWarning>
      <ModalAnswer :onClose="() => { this.$store.commit('setModalQuestion', { show: false, label: '' }); }"
        :show="this.$store.state.question?.show" :label="this.$store.state.question?.label"></ModalAnswer>
      <ModalSuccess :onClose="() => { this.$store.commit('setModalSuccess', { show: false, label: '' }); }"
        :show="this.$store.state.success?.show" :label="this.$store.state.success?.label"></ModalSuccess>
    </div>
  </div>
</template>
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MenuFooter from '@/components/MenuFooter.vue';
import ModalWarning from '@/components/ModalWarning.vue';
import ModalSuccess from '@/components/ModalSuccess.vue';
import ModalAnswer from '@/components/ModalAnswer.vue';
export default ({
  name: 'App',
  components: {
    MenuFooter,
    ModalWarning,
    ModalSuccess,
    ModalAnswer
  },
})
</script>
