<template>
    <a-modal :open="show" title="">
        <template #footer>
            <div style="width: 100%;text-align: center;">
                <a class="f-l btn-warn" @click="onClose">ok</a>
            </div>
        </template>
        <div style="height: 150px;height: 150px;text-align: center;color: #f2c205ad;">
            <MyIcon style="font-size: 80px;" name="exclamation-circle-outlined"/>
            <p style="margin-top: 25px;" class="f-m">{{ label }}</p>
        </div>
    </a-modal>
</template>
 
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MyIcon from '@/components/MyIcon.vue';
export default ({
    name: 'MyWarningModal',
    props: {
        show: Boolean,
        label: String,
        onClose: Function
    },
    components: {
        MyIcon
    },
})
</script>


<style>
.ant-select-dropdown {
    width: 75px !important;
}

.btn-warn {
    display: inline-block;
    background: white;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    padding: 5px;
    color: #f2c205ad;
    border: 1px solid #f2c205ad;
}

.btn-warn:hover {
    background: #f2c205ad;
    color: white;
}

.ant-modal-close {
    display: none !important;
}</style>